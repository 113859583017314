import { Modal, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { api } from "services";
import useParseLiveQuery from "hooks/LiveQueries/useParseLiveQuery";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import DeleteModal from "./DeleteModal";
import CheckResultStatus from "./CheckResultStatus";
import Wallet from "./wallet/Wallet";

const dateOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const Dashboard = () => {
  const [tableData, setTableData] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    position: ["bottomCenter"],
  });
  const [showModel, setShowModel] = useState(false);
  const [deleteProjectbyId, setDeleteProjectbyId] = useState(null);
  const { data, isConnected } = useParseLiveQuery("Project");

  useEffect(() => {
    setLoading(true);
    api.getProjects().then((results) => {
      setTableData(results);
    });
    setLoading(false);
  }, [data]);

  const handleShowModel = (value) => {
    setShowModel(value);
  };

  const handleOnDelete = (project) => {
    setDeleteProjectbyId(project);
    setShowModel(true);
  };

  const handleProjectDelete = () => {
    console.log(deleteProjectbyId);
    api.deleteProject(deleteProjectbyId).then((res) => {
      setDeleteProjectbyId("");
      setShowModel(false);
      notification["success"]({
        message: "Deleted",
        description: "Project is Deleted Sucesfully",
        duration: 5,
      });
    });
  };

  const column = [
    {
      align: "center",

      title: <b>Execution Date</b>,
      dataIndex: "createdAt",
      render: (project) => project.toLocaleString(undefined, dateOptions),
    },
    {
      align: "center",

      title: <b>Project Name</b>,
      dataIndex: "name",
      render: (_, project) => project.get("name"),
    },
    {
      align: "center",

      title: <b>Project Type</b>,
      dataIndex: "type",
      render: (_, project) => project.get("type"),
    },
    {
      align: "center",

      title: <b>Status</b>,
      dataIndex: "status",
      render: (_, project) => project.get("status"),
    },
    {
      align: "center",
      title: <b>Results</b>,
      dataIndex: "result",
      render: (_, project) => (
        <>
          <CheckResultStatus project={project} />
        </>
      ),
    },
    {
      align: "center",

      title: <b>Actions</b>,
      dataIndex: "id",
      render: (_, project) => (
        <>
          <div className="d-flex justify-content-center align-items-center">
            {/* {project.get("status") == "cancelled" ? (
              <></>
            ) : (
              <Button
                className="myButton"
                style={{ margin: 2 }}
                onClick={() => (window.location = `/logs/${project.id}`)}
                size="sm"
              >
                View Logs
              </Button>
            )} */}
            <Button
              className="myButton"
              size="sm"
              onClick={() => handleOnDelete(project)}
            >
              <DeleteOutlined style={{ margin: 2 }} />
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Wallet />
      <Table
        columns={column}
        dataSource={tableData}
        loading={loading}
        pagination={tableParams}
        onChange={(value) => {
          setTableParams(value);
        }}
        align="center"
      />
      <div>
        <DeleteModal
          showModel={showModel}
          handleShowModel={handleShowModel}
          handleProjectDelete={handleProjectDelete}
        />
      </div>
    </div>
  );
};

export default Dashboard;
