import React from "react";
import { Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import useImageLightBox from "hooks/Lightbox/useImageLightBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
const Drift = (props) => {
  const { title, data, path } = props;
  const { Imagebox, setData } = useImageLightBox();

  console.log(props);
  const keys = Object.keys(data);
  console.log(keys);
  var tableData = data[keys[3]];
  console.log(tableData);
  var columns = Object.keys(tableData[0]);
  var tableHtml = tableData.map((row, i) => {
    return (
      <tr key={i}>
        {columns.map((column, j) => (
          <td key={j}>
            {tableData[i][column] === false
              ? "false"
              : tableData[i][column] === true
              ? "true"
              : tableData[i][column]}
          </td>
        ))}
      </tr>
    );
  });
  // console.log(tableHtml);
  return (
    <section id="explainable">
      <Row>
        <Col md={12}>
          <h2 className="title">{title}</h2>
        </Col>
      </Row>
      <p>{data.one_liner}</p>
      <Col className="col-md-4">
        <h5 className="subtitle">Overall Summary</h5>
      </Col>
      <Table bordered responsive size="sm">
        <thead>
          <tr>
            {Object.keys(data.overall_summary[0]).map((rowHeader, i) => (
              <th key={i}>{rowHeader}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {" "}
          {Object.values(data.overall_summary[0]).map((rowHeader, i) => (
            <th key={i}>
              {rowHeader === false
                ? "false"
                : rowHeader === true
                ? "true"
                : rowHeader}
            </th>
          ))}
        </tbody>
      </Table>
      <br />
      <Row align="center">
        <Col className="col-md-4">
          <h5 className="subtitle">
            <a
              className="myLink"
              href={path + data.download_overall_drift_summary}
              target="_self"
            >
              Training Samples <FontAwesomeIcon icon={faArrowCircleDown} />
            </a>
          </h5>
        </Col>
        <Col className="col-md-4">
          <h5 className="subtitle">
            <a
              className="myLink"
              href={path + data.download_drift_report}
              target="_self"
            >
              Testing Samples <FontAwesomeIcon icon={faArrowCircleDown} />
            </a>
          </h5>
        </Col>
      </Row>
      <br />
      <Col className="col-md-4">
        <h5 className="subtitle">Drift Report</h5>
      </Col>
      <Table bordered responsive size="sm">
        <thead>
          <tr>
            {columns.map((rowHeader, i) => (
              <th key={i}>{rowHeader}</th>
            ))}
          </tr>
        </thead>
        <tbody>{tableHtml}</tbody>
      </Table>
      <br />
      <Tabs defaultActiveKey={columns[0]}>
        {columns.map((items, i) => {
          return (
            <Tab
              eventKey={items}
              title={items}
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={path + data.img_path[i]}
                onClick={() => {
                  setData(path + data.img_path[i]);
                }}
              />

              <Imagebox />
            </Tab>
          );
        })}
      </Tabs>
      {/* <Attack data={data} path={path} />; */}
      <br />
      <br />
    </section>
  );
};

export default Drift;
