import React, { useState } from "react";
import { Button, Row, Col, Alert } from "react-bootstrap";
import { notification } from "antd";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import "App.scss";
import { Link } from "react-router-dom";
import { dashboard, signup } from "services/paths";
import { Images } from "themes";
import { auth } from "services";

const Login = () => {
  const validate = Yup.object({
    email: Yup.string().email("please enter valid email").required("Required"),
    password: Yup.string().required("Password is required"),
  });
  return (
    <div className="row my-5" style={{ display: "flex" }}>
      <Col className="my-auto col-md-5 offset-md-2">
        <img className="img-fluid" src={Images.logo} alt="" />
      </Col>
      <div className="col-md-3">
        <Formik
          className="bg-dark"
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            auth
              .login(values.email.toLowerCase(), values.password)
              .then((user) => {
                notification["success"]({
                  message: "Login Successfully",
                  duration: 10,
                });
                setTimeout(() => {
                  window.location = dashboard;
                }, 1000);
              })
              .catch((error) => {
                notification["error"]({
                  message: "Login Error",
                  description: error.message,
                  duration: 5,
                });
              });
          }}
        >
          {(formik) => (
            <div className="p-3">
              <h1 className="my-4 font-weight-bold .display-4">Login</h1>
              <hr />
              <Form class="d-grid">
                <TextField
                  placeholder="Email"
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextField
                  placeholder="Password"
                  label="Password"
                  name="password"
                  type="password"
                />
                <Button className="btn btn-block my-3 myButton" type="submit">
                  Login
                </Button>
                <label className="myLinks ">
                  <Link to={signup}>
                    <i>Don't have an account? Register Here</i>
                  </Link>
                </label>
                {/* {errorMessage ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                  ) : null} */}
              </Form>
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
