import React, { useEffect, useState } from "react";
import Parse from "services/parse";

const useParseLiveQuery = (className) => {
  const [isConnected, setIsConnected] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    const initializeLiveQuery = async () => {
      const query = new Parse.Query(className);
      const subscription = await query.subscribe();

      const updateData = (object) => {
        setData(object);
      };

      const handleOpen = (object) => {
        // console.log("LiveQuery connection opened", object);
        setIsConnected(true);
      };

      const handleCreate = (object) => {
        // console.log("New Object Created", object);
        updateData(object);
      };

      const handleUpdate = (object) => {
        // console.log("Object Updated", object);
        updateData(object);
      };

      const handleDelete = (object) => {
        // console.log("Object Deleted", object);
        updateData(object);
      };

      const handleClose = () => {
        // console.log("LiveQuery connection closed");
        setIsConnected(false);
      };

      subscription.on("open", handleOpen);
      subscription.on("create", handleCreate);
      subscription.on("update", handleUpdate);
      subscription.on("delete", handleDelete);
      subscription.on("close", handleClose);

      await new Promise((resolve) => {
        subscription.on("open", resolve);
      });

      return () => {
        subscription.unsubscribe();
      };
    };

    initializeLiveQuery();
  }, [className]);

  return { data, isConnected };
};

export default useParseLiveQuery;
