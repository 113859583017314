import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import { steps } from "views/FileUploader/steps";
import { TextPipeline, ImagePipeline, Structured } from "./views/Report";
import { Login, Signup } from "./views/Auth";
import Dashboard from "./views/Pages/Dashboard";
import { auth } from "services";
import { home, dashboard, tags, login, signup, report } from "./services/paths";
import Home from "views/Pages/Home";
import { Footer, MainNavbar } from "components";
import Logs from "views/Pages/Dashboard/Logs";
import "./App.scss";
import StructuredPage from "views/Pages/StructuredPage";
import Pricing from "views/Pages/Pricing";

const App = (props) => {
  useEffect(() => {
    auth.getCurrentUser() && auth.getCurrentUser().fetch();
    if (auth.getCurrentUser()) {
      return;
    } else {
      auth.ParseAnonymousUser();
      console.log("im comming here");
    }
  }, []);

  return (
    <TourProvider
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          "--reactour-accent": "#a03c64",
          borderRadius: 10,
        }),
        maskArea: (base) => ({ ...base, rx: 10 }),
        maskWrapper: (base) => ({ ...base, color: "pink" }),
        badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
        controls: (base) => ({ ...base, marginTop: 100 }),
        close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
      }}
    >
      <Router>
        <MainNavbar />
        {auth.getCurrentUser() ? (
          <Routes>
            <Route exact path="/report/text" element={<TextPipeline />} />
            <Route
              exact
              path="/report/image/:reportId"
              element={<ImagePipeline />}
            />
            <Route
              exact
              path="/report/structured/:reportId"
              element={<Structured />}
            />
            <Route
              exact
              path="/report/text/:reportId"
              element={<TextPipeline />}
            />
            <Route exact path="/logs/:projectId" element={<Logs />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
          </Routes>
        ) : null}
        <Routes>
          <Route exact path="pricing" element={<Pricing />} />
          <Route exact path={login} element={<Login />} />
          <Route exact path={signup} element={<Signup />} />
          <Route exact path={home} element={<Home />} />
          <Route exact path="/structured" element={<StructuredPage />} />
        </Routes>
      </Router>
    </TourProvider>
  );
};
export default App;
