import { Upload } from "antd";
import React from "react";
import Parse from "services/parse";
import { FileMarkdownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Images } from "themes";

const UploadModel = (props) => {
  const { setModelFile, modelFile } = props;
  const onUploadModel = async (file) => {
    setModelFile((prev) => {
      const tempVar = { ...prev };
      tempVar.uploaded = true;
      return tempVar;
    });
    const parseFile = new Parse.File(file.name, file);
    try {
      const savedFile = await parseFile.save();
      setModelFile((prev) => {
        const tempVar = { ...prev };
        tempVar.name = file.name;
        tempVar.uploaded = false;
        return tempVar;
      });
      localStorage.setItem("model", savedFile._url);
    } catch (error) {}
  };
  return (
    <div>
      {" "}
      <div>
        <Upload.Dragger
          // accept=".h5, .pkl"
          accept=".h5"
          name="file"
          maxCount={1}
          showUploadList={false}
          className="second-step uploadbox"
          beforeUpload={(file) => onUploadModel(file)}
        >
          {modelFile.uploaded ? (
            <>
              <LoadingOutlined style={{ fontSize: 40, marginBottom: 10 }} />
              <div>
                <b>Uploading...</b>
              </div>
            </>
          ) : (
            <>
              <img
                className="image"
                src={Images.model}
                alt="AI Model"
                style={{ width: 80, height: 80 }}
              />
              <p className="parahgraph">
                {modelFile.name ? (
                  <>
                    <b>AI Model Uploaded</b>
                    <div>click to Re-Upload</div>
                  </>
                ) : (
                  <b>{"AI MODEL (*.h5)"}</b>
                )}
              </p>
            </>
          )}
        </Upload.Dragger>
        {modelFile.name && (
          <div>
            <FileMarkdownOutlined />
            <b>{modelFile.name}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadModel;
