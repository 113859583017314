import React, { useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

const useImageLightBox = () => {
  const [data, setData] = useState("");

  const handleClose = () => setData("");
  const Imagebox = () => {
    return (
      <div>
        {!!data && (
          <Lightbox
            mainSrc={data.src ? data.src : data}
            onCloseRequest={handleClose}
          />
        )}
      </div>
    );
  };
  return { Imagebox, setData };
};

export default useImageLightBox;
