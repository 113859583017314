import React, { useState, useEffect } from "react";
// import { Form } from "react-bootstrap";
import { Select, Form, Input } from "antd";
import "./index.css";
const { Option } = Select;

const errStyle = {
  color: "red",
  fontSize: 14,
  padding: 0,
  margin: 5,
  // fontWeight: "bold",
  // fontStyle: "italic",
};

export const ContactInfo = () => {
  return (
    <>
      <p style={{ fontSize: 20 }}>
        We Hope Everything is Going Fine,
        <br /> If Anything Goes Wrong Please Contact Us
        <br /> support@testaing.com
      </p>
    </>
  );
};

export const ModelSignUp = (props) => {
  const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const {
    fullName,
    setFullName,
    email,
    setEmail,
    setPassowrd,
    password,
    nameError,
    setNameError,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
  } = props;

  useEffect(() => {
    if (fullName.length < 3) {
      setNameError("Name must be 3 characters long!");
    } else {
      setNameError("");
    }
  }, [fullName]);

  useEffect(() => {
    if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter valid email!");
    } else {
      setEmailError("");
    }
  }, [email]);

  useEffect(() => {
    if (password.length < 6) {
      setPasswordError("Password must be 6 characters long!");
    } else {
      setPasswordError("");
    }
  }, [password]);

  return (
    <>
      <div>
        <h3>Signup To Proceed</h3>
        <hr />
        <Form autoComplete="off" layout="vertical">
          <Form.Item
            className="username"
            label="Full Name"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put your full name!",
              },
            ]}
          >
            <Input
              value={fullName}
              onChange={(event) => setFullName(event.target.value)}
              placeholder="Eg:- User"
              onBlur={() => setNameTouched(true)}
            />
            {nameTouched && nameError != "" && (
              <p style={errStyle}>{nameError}</p>
            )}
          </Form.Item>
          <Form.Item
            className="username"
            label="Email"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put your email!",
              },
            ]}
          >
            <Input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Eg:- user@testaing.com"
              onBlur={() => setEmailTouched(true)}
            />
            {emailTouched && emailError != "" && (
              <p style={errStyle}>{emailError}</p>
            )}
          </Form.Item>
          <Form.Item
            className="username"
            label="Password"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please your full name!",
              },
            ]}
          >
            <Input.Password
              value={password}
              placeholder="input password"
              onChange={(event) => setPassowrd(event.target.value)}
              autoComplete="off"
              onBlur={() => setPasswordTouched(true)}
            />
            {passwordTouched && passwordError != "" && (
              <p style={errStyle}>{passwordError}</p>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export const ModelTopic = (props) => {
  const [topicTouched, setTopicTouched] = useState(false);
  const { topic, setTopic, inputError, setInputError } = props;
  useEffect(() => {
    if (topic.length < 3) {
      setInputError("Project Name must be atleast 3 characters long");
    } else {
      setInputError("");
    }
  }, [topic]);

  return (
    <>
      <div>
        <h3>Enter Project Name</h3>

        <br />
        <Form layout="vertical">
          <Form.Item
            className="username"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please put full project",
              },
            ]}
          >
            <Input
              value={topic}
              onChange={(event) => setTopic(event.target.value)}
              placeholder="Demo Project"
              onBlur={() => setTopicTouched(true)}
            />
            {topicTouched && inputError != "" && (
              <p style={errStyle}>{inputError}</p>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export const ModelInput = (props) => {
  const [inputTouched, setInputTouched] = useState(false);
  const { colInput, setColInput, inputError, setInputError } = props;
  useEffect(() => {
    if (colInput.length < 1) {
      setInputError("Enter Column Name");
    } else {
      setInputError("");
    }
  }, [colInput]);

  return (
    <>
      <div>
        <h3>Enter Column Name</h3>

        <br />
        <Form layout="vertical">
          <Form.Item
            className="username"
            colon={false}
            rules={[
              {
                required: true,
                message: "Please enter column name",
              },
            ]}
          >
            <Input
              value={colInput}
              onChange={(event) => setColInput(event.target.value)}
              placeholder="elephant"
              onBlur={() => setInputTouched(true)}
            />
            {inputTouched && inputError != "" && (
              <p style={errStyle}>{inputError}</p>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
