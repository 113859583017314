import React, { lazy, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Footer, Navigation, Loader } from "components";
import ScrollToTop from "react-scroll-to-top";
import Drift from "./Drift/Drift";
import { useParams } from "react-router-dom";
import { api } from "services";

const GlobalVisualization = lazy(() => import("./GlobalVisualization"));
const EDA = lazy(() => import("./EDA"));
const LocalVisualization = lazy(() => import("./LocalVisualization"));
const DataPaths = lazy(() => import("./DataPaths"));
const Bias = lazy(() => import("./Bias"));
const Art = lazy(() => import("./Art"));
const Synthetic = lazy(() => import("./Synthetic"));
const CounterFactual = lazy(() => import("./CounterFactual"));
const Metamorphic = lazy(() => import("./Metamorphic"));
const BBbox = lazy(() => import("./BBbox"));
const FeedbackLoop = lazy(() => import("./FeedbackLoop"));
const PerformanceTesting = lazy(() => import("./PerformanceTesting"));
const Debias = lazy(() => import("./Debias"));

const Index = () => {
  const [path, setPath] = useState(null);
  const [data, setData] = useState(null);
  const { reportId } = useParams();

  useEffect(() => {
    api.getResults(reportId).then((res) => {
      setPath(res);
      setPickedData(res);
    });
  }, []);
  console.log("Im called Structured");
  const setPickedData = (_path) => {
    fetch(_path + "json_metadata.json")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  if (data == null) return <Loader />;
  var keys = Object.keys(data);

  return (
    <Container className="main">
      <Navigation data={keys} />
      <hr />
      {keys.includes("Data Preprocessing & Modelling") ? (
        <DataPaths
          title="Data Preprocessing & Modelling"
          data={data["Data Preprocessing & Modelling"]}
          path={path}
        />
      ) : null}
      {keys.includes("Exploratory Data Analysis") ? (
        <EDA
          title="Exploratory Data Analysis"
          data={data["Exploratory Data Analysis"]}
          path={path}
        />
      ) : null}
      {keys.includes("Global Explainability") ? (
        <GlobalVisualization
          title="Global Explainability"
          data={data["Global Explainability"]}
          path={path}
        />
      ) : null}
      {keys.includes("Local Explainability") ? (
        <LocalVisualization
          title="Local Explainability"
          data={data["Local Explainability"]}
          path={path}
        />
      ) : null}
      {keys.includes("Bias Techniques") ? (
        <Bias
          title="Bias Techniques"
          data={data["Bias Techniques"]}
          path={path}
        />
      ) : null}
      {keys.includes("Adversarial Testing") ? (
        <Art
          title="Adversarial Testing"
          data={data["Adversarial Testing"]}
          path={path}
        />
      ) : null}
      {keys.includes("Synthetic Data") ? (
        <Synthetic
          title="Synthetic Data"
          data={data["Synthetic Data"]}
          path={path}
        />
      ) : null}
      {keys.includes("Metamorphic Testing") ? (
        <Metamorphic
          title="Metamorphic Testing"
          data={data["Metamorphic Testing"]}
          path={path}
        />
      ) : null}
      {keys.includes("Performance Testing") ? (
        <PerformanceTesting
          title="Performance Testing"
          data={data["Performance Testing"]}
          path={path}
        />
      ) : null}
      {keys.includes("Counterfactuals Generated") ? (
        <CounterFactual
          title="Counterfactuals Generated"
          data={data["Counterfactuals Generated"]}
          path={path}
        />
      ) : null}
      {keys.includes("BBbox Audit") ? (
        <BBbox title="BBbox Audit" data={data["BBbox Audit"]} path={path} />
      ) : null}
      {keys.includes("Feedback Loop") ? (
        <FeedbackLoop
          title="Feedback Loop"
          data={data["Feedback Loop"]}
          path={path}
        />
      ) : null}
      {keys.includes("Debias") ? (
        <Debias title="Debias" data={data["Debias"]} path={path} />
      ) : null}
      {keys.includes("Drift") ? (
        <Drift title="Drift" data={data["Drift"]} path={path} />
      ) : null}

      <Footer />
      <ScrollToTop smooth color="#A03C64" style={{ marginBottom: 100 }} />
    </Container>
  );
};

export default Index;
