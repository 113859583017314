import Facebook from "images/svg/Facebook";
import Instagram from "images/svg/Instagram";
import Linkdin from "images/svg/Linkdin";
import Twitter from "images/svg/Twitter";
import Youtube from "images/svg/Youtube";
import { Images } from "themes";
import "../App.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="row mx-5">
        <div class="col">
          <img src={Images.logo} alt="" />
          <p className="foopara">AiEnsured is Testing Suite for AI Products.</p>
        </div>
        <div className="col footag" align="right">
          <Facebook className="socialmediaicons" />
          <Linkdin className="socialmediaicons" />
          <Twitter className="socialmediaicons" />
          <Facebook className="socialmediaicons" />
          <Youtube className="socialmediaicons" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
