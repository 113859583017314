import Parse from "./parse";

export function resetPassword(username) {
  return Parse.User.requestPasswordReset(username);
}

export function getCurrentUser() {
  return Parse.User.current();
}
export const ParseAnonymousUser = async () => {
  return await Parse.AnonymousUtils.logIn().then((user) => {
    user.set("credits", 20);
    user.save();
  });
};

export function login(username, password) {
  return Parse.User.logIn(username, password, { usePost: true });
}

export function logout() {
  return Parse.User.logOut();
}

export function newAnonymousUser() {
  return new Parse.User();
}

export function signup(username, password) {
  const user = new Parse.User();
  user.set("username", username);
  user.set("password", password);
  user.set("email", username);
  user.set("onboarding", false);
  user
    .signUp()
    .then(() => {
      window.location = "/auth/login";
    })
    .catch((error) => console.log(error.message));
}
