import { Upload } from "antd";
import React, { useState } from "react";
import Parse from "services/parse";
import { FileMarkdownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Images } from "themes";

const UploadFile = (props) => {
  const { filetype, filetypeHeader, filetypeExtention, setIsUploaded } = props;
  const [fileState, setfFileState] = useState({
    name: "",
    uploading: false,
    uploaded: false,
  });

  const OnUploadFile = async (file) => {
    setfFileState((prev) => ({ ...prev, uploading: true }));

    const parseFile = new Parse.File(file.name, file);
    try {
      const savedFile = await parseFile.save();
      console.log(savedFile._url);
      setfFileState({ name: file.name, uploading: false, uploaded: true });
      localStorage.setItem(filetype, savedFile._url);
      setIsUploaded((prev) => ({ ...prev, [filetype]: true }));
    } catch (error) {
      console.error("Error uploading model:", error);
    }
  };

  return (
    <div>
      <div>
        <Upload.Dragger
          accept={filetypeExtention}
          name="file"
          maxCount={1}
          showUploadList={false}
          className="second-step uploadbox"
          beforeUpload={(file) => OnUploadFile(file)}
        >
          {fileState.uploading ? (
            <>
              <LoadingOutlined style={{ fontSize: 40, marginBottom: 10 }} />
              <div>
                <b>Uploading...</b>
              </div>
            </>
          ) : (
            <>
              <img
                className="image"
                src={Images.model}
                alt="files"
                style={{ width: 80, height: 80 }}
              />
              <p className="parahgraph">
                {fileState.name ? (
                  <>
                    <b>{`${filetypeHeader} Uploaded`}</b>
                    <div>click to Re-Upload</div>
                  </>
                ) : (
                  <b>{`${filetypeHeader} (*${filetypeExtention})`}</b>
                )}
              </p>
            </>
          )}
        </Upload.Dragger>
        {fileState.name && (
          <div>
            <FileMarkdownOutlined />
            <b>{fileState.name}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
