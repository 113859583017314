import "./styles.css";
import "App.css";

import {
  CloseCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card, Form } from "react-bootstrap";
import ComponentModal from "forms/Modal";
import DragDrop from "./DragDrop";
import { Footer } from "components";
import Parse from "services/parse";
import UploadModel from "./UploadModel";
import { auth } from "services";
import { notification } from "antd";
import { Select } from "antd";
import Navigator from "../components/Navigator";

const getWidthForClassName = (className) => {
  const inputElement = document.createElement("span");
  inputElement.className = "hidden-input";
  inputElement.textContent = className;
  document.body.appendChild(inputElement);
  const width = inputElement.offsetWidth * 2 + 6; // Add some padding
  console.log(width);
  document.body.removeChild(inputElement);
  return width;
};

const Home = () => {
  const [classes, setClasses] = useState([
    {
      uploadState: false,
      className: "Class_1",
      images: [],
      width: getWidthForClassName("Class_1"),
    },
    {
      uploadState: false,
      className: "Class_2",
      images: [],
      width: getWidthForClassName("Class_2"),
    },
  ]);
  const [isDragging, setIsDragging] = useState(false);
  const [editable, setEditable] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [storedImages, setStoredImages] = useState({});
  const [progress, setProgress] = useState({ percentage: 0, completed: false });
  const [modelFile, setModelFile] = useState({ name: null, uploaded: false });
  const [loggineduser, setLoggineduser] = useState(null);
  const [canUpload, setCanUpload] = useState({
    minNumberOfImages: false,
    uploaded: false,
    uploading: false,
  });

  var CurrentUser = auth.getCurrentUser();
  var authData = CurrentUser ? CurrentUser.get("authData") : null;
  console.log(canUpload);
  useEffect(() => {
    setCanUpload((prev) => {
      let tempVar = { ...prev };
      tempVar.uploaded = false;
      if (classes.every((item) => item.images.length >= 5)) {
        tempVar.minNumberOfImages = true;
      } else {
        tempVar.minNumberOfImages = false;
      }
      return tempVar; // Return the updated value
    });
  }, [classes]);

  useEffect(() => {
    if (authData !== undefined && authData?.anonymous !== undefined) {
      console.log("Non Loginned User");
      setLoggineduser(false);
    } else if (CurrentUser) {
      console.log("Loginned User");
      setLoggineduser(true);
    } else {
      console.log("anon user");
      setLoggineduser(false);
    }
  }, []);

  function removeSpecialCharactersAndLastTwoDots(str) {
    // Define the regular expression to match special characters and last two dots
    const regex = /[!#()\[\]]|\.\.$/g;
    // Replace matched characters with an empty string
    const result = str.replace(regex, "");
    return result;
  }
  const onFileSelect = (e, classIndex) => {
    const files = e.target.files || e.dataTransfer.files; // Use e.dataTransfer.files for drag-and-drop
    if (!files || files.length === 0) return;

    const newImages = Array.from(files)
      .filter((file) => file.type.split("/")[0] === "image")
      .map((file) => ({
        name: removeSpecialCharactersAndLastTwoDots(file.name),
        url: URL.createObjectURL(file),
      }));

    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = [
        ...newClasses[classIndex].images,
        ...newImages,
      ];
      return newClasses;
    });
  };
  console.log(classes);
  const deleteImage = (classIndex, imageIndex) => {
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = newClasses[classIndex].images.filter(
        (_, i) => i !== imageIndex
      );
      return newClasses;
    });
  };

  const onDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (event, classIndex) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;

    const newImages = Array.from(files)
      .filter((file) => file.type.split("/")[0] === "image")
      .map((file) => ({
        name: file.name,
        url: URL.createObjectURL(file),
      }));

    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].images = [
        ...newClasses[classIndex].images,
        ...newImages,
      ];
      return newClasses;
    });
  };

  const addNewClass = () => {
    setClasses((prevClasses) => {
      const className = `Class ${prevClasses.length + 1}`;

      return [
        ...prevClasses,
        {
          className: className,
          images: [],
          width: getWidthForClassName(className),
        },
      ];
    });
  };

  const handleEditClick = (index) => {
    setEditable((prev) => !prev);
    setFocusedIndex(index);
  };

  const handleClassNameChange = (e, classIndex) => {
    const newClassName = e.target.value;
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex].className = newClassName;
      newClasses[classIndex].width = getWidthForClassName(newClassName);
      return newClasses;
    });
  };

  const uploadButtonToggle = (classIndex) => {
    setClasses((prevClasses) => {
      const newClasses = [...prevClasses];
      newClasses[classIndex] = {
        ...newClasses[classIndex],
        uploadState: !newClasses[classIndex].uploadState,
      };
      return newClasses;
    });
  };

  // useEffect(() => {
  //   const updatedClasses = classes.map((classData, index) => {
  //     const inputElement = document.createElement("span");
  //     inputElement.className = "hidden-input";
  //     inputElement.textContent = classData.className;
  //     document.body.appendChild(inputElement);
  //     const width = inputElement.offsetWidth + 20; // Add some padding
  //     document.body.removeChild(inputElement);
  //     return { ...classData, width };
  //   });

  //   // Check if there are changes to avoid triggering a loop
  //   if (JSON.stringify(updatedClasses) !== JSON.stringify(classes)) {
  //     setClasses(updatedClasses);
  //   }
  // }, [classes]);

  useEffect(() => {
    if (editable && focusedIndex !== null) {
      const inputElement = document.querySelector(
        `.class_name_${focusedIndex}`
      );
      inputElement.focus();
    }
  }, [editable, focusedIndex]);

  useEffect(() => {
    localStorage.setItem("dataset", JSON.stringify(storedImages));
  }, [progress.completed]);

  const uploadPhotos = async () => {
    setCanUpload((prev) => {
      let tempVariable = { ...prev };
      tempVariable.uploading = true;
      return tempVariable;
    });
    //loaders are not used now but it may use in future
    const totalImages = classes.reduce(
      (sum, item) => sum + (item.images ? item.images.length : 0),
      0
    );
    let uploadedImages = 0;

    setProgress((prev) => {
      const tempvar = { ...prev };
      tempvar.completed = false;
      return tempvar;
    });

    const promises = classes.map(async (classData) => {
      const classImagesPromises = classData.images.map(async (image, index) => {
        try {
          const response = await fetch(image.url);
          const blob = await response.blob();

          const parseFile = new Parse.File(image.name, blob);

          try {
            const savedFile = await parseFile.save();

            // Update stored data with Parse file URL for the specific class
            setStoredImages((prevStoredData) => {
              const newStoredData = { ...prevStoredData };
              const className = classData.className;

              // Ensure the class entry exists in storedData
              if (!newStoredData[className]) {
                newStoredData[className] = { Uris: [] };
              }

              newStoredData[className].Uris[index] = savedFile.url();

              return newStoredData;
            });

            console.log(`File uploaded successfully: ${savedFile.url()}`);

            uploadedImages += 1;
            const progress = (uploadedImages / totalImages) * 100;
            updateProgressBar(progress);
          } catch (error) {
            console.error("Error uploading file:", error);
          }
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      });

      // Wait for all file uploads for the current class to complete
      await Promise.all(classImagesPromises);
    });

    // Wait for all class uploads to complete
    await Promise.all(promises);
    setCanUpload((prev) => {
      let tempVar = { ...prev };
      tempVar.uploaded = true;
      tempVar.uploading = false;
      return tempVar; // Return the updated value
    });
  };

  const updateProgressBar = (progress) => {
    console.log(`Progress: ${Math.round(progress)}%`);
    setProgress((prev) => {
      const tempvar = { ...prev };
      tempvar.percentage = Math.round(progress);
      if (tempvar.percentage === 100) {
        tempvar.completed = true;
      }
      return tempvar;
    });
    // Update your progress bar or any other UI element here
  };
  const deleteClass = (classIndex) => {
    if (classes.length > 2) {
      setClasses((prevClasses) => {
        const newClasses = [...prevClasses];
        newClasses.splice(classIndex, 1);
        return newClasses;
      });
    } else {
      notification["error"]({
        message: "Minimum 2 Classes are Required",
        description: "You cannot remove all the classes",
        duration: 5,
      });
    }
  };
  return (
    <div className="top_container">
      <Navigator />
      <div className="main_container">
        <div className="primary">
          {classes.map((classData, classIndex) => (
            <Card className="main_card" key={classIndex}>
              <Card.Header>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "18px",
                      marginLeft: "10px",
                    }}
                  >
                    <input
                      className={`class_name class_name_${classIndex} ${
                        editable && focusedIndex === classIndex
                          ? "edit_classname"
                          : ""
                      }`}
                      type="text"
                      value={classData.className}
                      disabled={!editable}
                      onChange={(e) => handleClassNameChange(e, classIndex)}
                      autoFocus={editable && focusedIndex === classIndex}
                      style={{ width: `${classData.width}px` }}
                    />
                    <EditOutlined
                      className="edit_icon"
                      onClick={() => handleEditClick(classIndex)}
                    />
                  </span>
                  <span
                    style={{ marginRight: "-5px" }}
                    onClick={() => {
                      deleteClass(classIndex);
                    }}
                  >
                    <CloseCircleOutlined
                      style={{
                        fontSize: "20px",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {classData.images.length != 0
                    ? classData.images.length + " / 5 Images "
                    : "Add Images (Minimum 5)"}
                </Card.Title>

                <DragDrop
                  key={classIndex}
                  uploadButtonToggle={() => uploadButtonToggle(classIndex)}
                  classData={classData}
                  classIndex={classIndex}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  isDragging={isDragging}
                  onFileSelect={onFileSelect}
                  deleteImage={deleteImage}
                />
              </Card.Body>
            </Card>
          ))}
          <div className="add_class" onClick={addNewClass}>
            <PlusCircleOutlined /> Add New Class
          </div>
          <br />
          <button
            disabled={
              canUpload.uploaded === false &&
              canUpload.uploading === false &&
              classes.every((item) => item.images.length >= 5)
                ? false
                : true
            }
            onClick={() => {
              uploadPhotos();
            }}
            className="btn btn-block btn-primary uploadBtn"
            //disabled={classes[0].images.length < 5 || classes[1].images.length < 5 || classes[2].images.length < 5}
            style={{
              background:
                progress.percentage > 0 && progress.percentage < 100
                  ? `linear-gradient(to right, green ${progress.percentage}%, transparent ${progress.percentage}%)`
                  : "#a03c64",
            }}
          >
            <b>
              {canUpload.uploaded == false ? (
                "Upload Images"
              ) : progress.percentage === 100 && canUpload.uploaded == true ? (
                <i class="bi bi-check2-circle">&nbsp; Uploaded</i>
              ) : (
                "Uploading"
              )}
            </b>
          </button>
        </div>

        <div className="model_uploadarea">
          <UploadModel setModelFile={setModelFile} modelFile={modelFile} />
          <div>
            {/* { ? ( */}
            <ComponentModal
              isDisabled={
                canUpload.uploaded &&
                canUpload.minNumberOfImages &&
                modelFile.name !== null
                  ? false
                  : true
              }
              loggineduser={loggineduser}
              className="modalswitchbutton py-5"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Home;
