import React, { useState, useEffect, lazy } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Navigation from "./Navigation";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";

//import { ReactComponent as MySVG } from "../../images/uparrow.svg";
import {
  Footer,
  Loader,
  //,Navigation
} from "components";
import UserContext from "views/Auth/User";
import { api, auth } from "services";

const ModelQuantization = lazy(() => import("./ModelQuantization"));
const DeepXplore = lazy(() => import("./DeepXplore"));
const FeedbackLoop = lazy(() => import("./FeedbackLoop"));
const ModelingPipeline = lazy(() => import("./ModelingPipeline"));
const LoadingData = lazy(() => import("./LoadingData"));
const MetamorphicTesting = lazy(() => import("./MetamorphicTesting"));
const ModelExplainability = lazy(() => import("./ModelExplainability"));
const AttackVectors = lazy(() => import("./AttackVectors"));
const ModelPrivacy = lazy(() => import("./ModelPrivacy"));
const PerformanceTesting = lazy(() => import("./PerformanceTesting"));

const Index = (props) => {
  const [path, setPath] = useState(null);
  const [data, setData] = useState(null);
  const { reportId } = useParams();

  useEffect(() => {
    api.getResults(reportId).then((res) => {
      setPath(res);
      setPickedData(res);
    });
  }, []);

  const setPickedData = (_path) => {
    fetch(_path + "json_metadata.json")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  if (data == null) return <Loader />;
  var keys = Object.keys(data);
  console.log(keys);
  var user = auth.getCurrentUser();

  return (
    <UserContext.Provider value={user}>
      <Container className="main">
        <Navigation data={keys} />
        <hr />
        {keys.includes("Data Diagnostic") ? (
          <LoadingData
            title="Data Diagnostic"
            data={data["Data Diagnostic"]}
            path={path}
          />
        ) : null}


        {keys.includes("Modeling Pipeline") ? (
          <ModelingPipeline
            title="Modeling Pipeline"
            data={data["Modeling Pipeline"]}
            path={path}
          />
        ) : null}
        {keys.includes("Model Explainability") ? (
          <ModelExplainability
            title="Model Explainability"
            data={data["Model Explainability"]}
            path={path}
          />
        ) : null}
        {keys.includes("Attack Vectors") ? (
          <AttackVectors
            title="Attack Vectors"
            data={data["Attack Vectors"]}
            path={path}
          />
        ) : null}
        {keys.includes("Metamorphic Testing") ? (
          <MetamorphicTesting
            title="Metamorphic Testing"
            data={data["Metamorphic Testing"]}
            path={path}
          />
        ) : null}
        {keys.includes("DeepXplore Corner Cases") ? (
          <DeepXplore
            title="DeepXplore Corner Cases"
            data={data["DeepXplore Corner Cases"]}
            path={path}
          />
        ) : null}
        {keys.includes("Feedback Loop") ? (
          <FeedbackLoop
            title="Feedback Loop"
            data={data["Feedback Loop"]}
            path={path}
          />
        ) : null}
        {keys.includes("Model Privacy") ? (
          <ModelPrivacy
            title="Model Privacy"
            data={data["Model Privacy"]}
            path={path}
          />
        ) : null}
        {keys.includes("Model Quantization") ? (
          <ModelQuantization
            title="Model Quantization"
            data={data["Model Quantization"]}
            path={path}
          />
        ) : null}
        {keys.includes("Performance Testing") ? (
          <PerformanceTesting
            title="Performance Testing"
            data={data["Performance Testing"]}
            path={path}
          />
        ) : null}
        <ScrollToTop smooth style={{ marginBottom: 100 }} />
      </Container>
    </UserContext.Provider>
  );
};

export default Index;
