import useParseLiveQuery from "hooks/LiveQueries/useParseLiveQuery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services";

const Logs = () => {
  const [project, setProject] = useState([]);
  const { data, isConnected } = useParseLiveQuery("Logs");

  const location = useLocation();
  const pathname = location.pathname;
  const segments = pathname.split("/");
  const lastRoute = segments[segments.length - 1];
  console.log(lastRoute);

  useEffect(() => {
    api.getProjectById(lastRoute).then((results) => {
      console.log(results);
      if (results) {
        setProject(results.get("logs"));
      }
    });
  }, [data]);

  return (
    <div>
      <div className="font-monospace mx-md-5 mt-5">
        {project.length > 0 ? (
          project.map((logs, i) => {
            return <p key={i}>{logs}</p>;
          })
        ) : (
          <div>
            <h3>Project is cancelled by the user</h3>
            <p>No logs</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Logs;
