export const steps = [
  {
    selector: ".first-step",
    content: "Upload Dataset (*.zip). Categorize images in various folders with name of classes & Compress into a zip file.",
  },
  {
    selector: ".second-step",
    content: "Upload Model in Keras format (.h5) from Teachable Machine",
  },
  {
    selector: ".third-step",
    content: "Upload text file (e.g. labels.txt) containing class names.",
  }
  // ...
];
