import React, { useState } from "react";
import { Button, Row, Col, Alert } from "react-bootstrap";
import { notification } from "antd";
import { Formik, Form } from "formik";
import { TextField } from "./TextField";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "App.scss";
import { Link } from "react-router-dom";
import { login } from "services/paths";
import { Images } from "themes";
import { auth } from "services";

const Signup = () => {
  let navigate = useNavigate();

  const validate = Yup.object({
    fullName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 charaters")
      .matches(/(?=.*[0-9])/, "Password must contain a number."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm password is required"),
  });
  return (
    <div className="row my-5" style={{ display: "flex" }}>
      <Col className="my-auto col-md-5 offset-md-2">
        <img className="img-fluid" src={Images.logo} alt="logo" />
      </Col>
      <div className="col-md-3">
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            const user = auth.getCurrentUser()
              ? auth.getCurrentUser()
              : auth.newAnonymousUser();
            user.set("fullname", values.fullName);
            user.setUsername(values.email.toLowerCase());
            user.setPassword(values.password);
            user.set("email", values.email);
            user.set("credits", 20);

            user
              .signUp()
              .then(() => {
                notification["success"]({
                  message: "Registered Successfully",
                  description:
                    "Your Account has Been Created, Check Your Mail Inbox to Verify",
                  duration: 5,
                });
                navigate(login);
              })
              .catch((error) => {
                notification["error"]({
                  message: "Login Error",
                  description: error.message,
                  duration: 5,
                });
              });
          }}
        >
          {(formik) => (
            <div className="p-3">
              <h1 className="my-4 font-weight-bold .display-4">Sign Up</h1>
              <hr />
              <Form className="d-grid">
                <TextField
                  placeholder="Eg:- User"
                  label="Full Name"
                  name="fullName"
                  type="text"
                />
                <TextField
                  placeholder="Eg:- user@testaing.com"
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextField
                  placeholder="Password"
                  label="Password"
                  name="password"
                  type="password"
                />
                <TextField
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                />
                <Button className="btn btn-block my-3 myButton" type="submit">
                  Register
                </Button>
                <div>
                  <label className="myLinks text-white">
                    <Link to="/login">Already have Account ?</Link>
                  </label>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Signup;
