import { WarningOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { Button } from "react-bootstrap";

const DeleteModal = (props) => {
  const { handleShowModel, handleProjectDelete, showModel } = props;
  return (
    <div>
      {" "}
      <Modal
        title=<b>
          <WarningOutlined style={{ fontSize: "30" }} /> Warning !
        </b>
        open={showModel}
        onCancel={() => handleShowModel(false)}
        footer={[
          <Button
            style={{ marginRight: "20px" }}
            className="myButton-Outline"
            onClick={() => handleShowModel(false)}
          >
            Cancel
          </Button>,
          <Button className="myButton" onClick={() => handleProjectDelete()}>
            Confirm
          </Button>,
        ]}
      >
        <h5>Do You Want To Delete This Project ?</h5>
      </Modal>
    </div>
  );
};

export default DeleteModal;
