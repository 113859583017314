import React, { useRef } from "react";
import {
  CloseCircleOutlined,
  UploadOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";

const DragDrop = (props) => {
  const {
    uploadButtonToggle,
    classData,
    classIndex,
    onDragOver,
    onDragLeave,
    onDrop,
    isDragging,
    onFileSelect,
    deleteImage,
  } = props;
  const fileInputRef = useRef(null);

  return (
    <div>
      {!classData.uploadState ? (
        <>
          <div
            className="upload_icon_container"
            onClick={() => uploadButtonToggle(classIndex)}
          >
            <UploadOutlined className="upload_icon" />
            <h6>upload</h6>
          </div>
        </>
      ) : (
        <div>
          <div>
            <CloseCircleOutlined
              style={{ fontSize: 20 }}
              className="close_icon"
              onClick={() => uploadButtonToggle(classIndex)}
            />
          </div>

          <div className="main_home">
            <div
              className="drag_area"
              onClick={() => {
                fileInputRef.current.click();
              }}
              onDragOver={(event) => onDragOver(event, classIndex)}
              onDragLeave={onDragLeave}
              onDrop={(event) => onDrop(event, classIndex)}
            >
              {isDragging ? (
                <div className="imagedroparea">
                  <span className="select">Drop Images Here</span>
                </div>
              ) : (
                <>
                  <div className="drop_area">
                    <>
                      <FolderAddOutlined className="file_icon" />
                      <span>Select Images</span>
                      {/* <span> or drag & drop images here</span> */}
                    </>
                  </div>
                </>
              )}

              <input
                type="file"
                className="file"
                multiple
                ref={fileInputRef}
                onChange={(e) => onFileSelect(e, classIndex)}
              />
            </div>

            <div className="container">
              {classData.images.map((image, imageIndex) => (
                <div className="image" key={imageIndex}>
                  <span
                    className="delete"
                    onClick={() => deleteImage(classIndex, imageIndex)}
                  >
                    <CloseCircleOutlined className="delete_icon" />
                  </span>
                  <img className="image" src={image.url} alt={image.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragDrop;
