import React from "react";
import { Form } from "react-bootstrap";

const Navigator = () => {
  const routes = [
    { path: "/", name: "Image Pipeline" },
    { path: "/structured", name: "Structured Pipeline" },
    // { path: "/text", name: "Text Pipeline" },
  ];
  return (
    <div>
      <h1 className="Title display-1 py-3">Test Your AI Model</h1>
      <div className="Title display-1 py-3">
        <Form.Select
          value={window.location.pathname}
          onChange={(e) => (window.location.href = e.target.value)}
        >
          {routes.map((route, index) => (
            <option key={index} value={route.path}>
              {route.name}
            </option>
          ))}
        </Form.Select>
      </div>
    </div>
  );
};

export default Navigator;
