import React, { useEffect, useState } from "react";
import UploadFile from "../components/UploadFile";
import ComponentModal from "forms/Modal";
import { auth } from "services";
import Navigator from "../components/Navigator";

const StructuredPage = () => {
  const [isUploaded, setIsUploaded] = useState({
    dataset: false,
    model: false,
  });

  const [loggineduser, setLoggineduser] = useState(null);
  console.log(isUploaded);
  var CurrentUser = auth.getCurrentUser();
  var authData = CurrentUser ? CurrentUser.get("authData") : null;
  useEffect(() => {
    if (authData !== undefined && authData?.anonymous !== undefined) {
      console.log("Non Loginned User");
      setLoggineduser(false);
    } else if (CurrentUser) {
      console.log("Loginned User");
      setLoggineduser(true);
    } else {
      console.log("anon user");
      setLoggineduser(false);
    }
  }, []);
  return (
    <div className="d-flex flex-column  align-items-center">
      <Navigator />

      <div className="d-flex flex-row mt-lg-5">
        <div className="text-center" style={{ marginRight: "250px" }}>
          <UploadFile
            filetype={"dataset"}
            filetypeHeader={"Datasets"}
            filetypeExtention={".csv, .xlsx"}
            setIsUploaded={setIsUploaded}
          />
        </div>
        <div className="text-center ">
          <UploadFile
            filetype={"model"}
            filetypeHeader={"AI Model"}
            filetypeExtention={".pkl, .h5"}
            setIsUploaded={setIsUploaded}
          />
        </div>
      </div>
      {}
      <ComponentModal
        loggineduser={loggineduser}
        isDisabled={!isUploaded.dataset || !isUploaded.model}
      />
    </div>
  );
};

export default StructuredPage;
