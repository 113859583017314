import { notification } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { cancelledByUser, terminateInstance } from "services/api.service";

const CheckResultStatus = (props) => {
  const { project } = props;
  // console.log(project);

  const checkProjectType = (project) => {
    console.log("sss");
    switch (project.get("type")) {
      case "image":
        window.location = `/report/image/${project.id}`;
        break;
      case "structured":
        window.location = `/report/structured/${project.id}`;
        break;
      default:
        return "Project";
    }
  };
  const checkStatus = (project) => {
    const handleOnClick = () => {
      console.log("ok");
      checkProjectType(project);
    };
    switch (project.get("status")) {
      case "success":
        return (
          <>
            <Button
              className="myButton"
              style={{ marginRight: 4 }}
              onClick={() => {
                handleOnClick();
              }}
              size="sm"
            >
              View Report
            </Button>
            <Button
              className="myButton"
              onClick={() =>
                (window.location = `${project.get("result")}Results.zip`)
              }
              size="sm"
            >
              <i class="bi bi-file-earmark-arrow-down"></i>
            </Button>
          </>
        );
      case "started":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div class="progress " style={{ width: "55%" }}>
              <div
                class="progress-bar theme-color progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="15"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "80%" }}
              >
                Estimated Time 10min
              </div>
            </div>
            <Button
              className="myButton"
              size="sm"
              onClick={async () => {
                await cancelledByUser(project.id).then((res) => {
                  notification["error"]({
                    message: "Error",
                    description: "Sucessfully Cancelled",
                    duration: 5,
                  });
                });
              }}
            >
              Cancel
            </Button>
          </div>
        );
      case "error":
        return (
          <>
            <i class="bi bi-exclamation-diamond">
              &nbsp;Pipeline failed to execute. Kindly re-check your
              Dataset/Model.
            </i>
          </>
        );
      case "cancelled":
        return (
          <>
            <i class="bi bi-exclamation-diamond">&nbsp; Cancelled By User.</i>
          </>
        );
      default:
        break;
    }
  };

  return <>{checkStatus(project)}</>;
};

export default CheckResultStatus;
