import { auth } from "services";
const CurrentUserID = auth.getCurrentUser()?.id;
console.log("sdsds : " + CurrentUserID);

export const home = `/`;
export const dashboard = `/dashboard`;
export const login = `/login`;
export const signup = `/signup`;
export const tags = `/${CurrentUserID}/tags`;
export const report = `/report`;
export const pricing = "/pricing";
